@font-face {
    font-family: "Mitr";
    src: url(./font/Mitr-Regular.eot);
    src: url(./font/Mitr-Regular.eot?#iefix) format("embedded-opentype"),
      url(./font/Mitr-Regular.woff2) format("woff2"),
      url(./font/Mitr-Regular.woff) format("woff"),
      url(./font/Mitr-Regular.ttf) format("truetype"),
      url(./font/Mitr-Regular.svg#noto_sans_thairegular) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }

  @font-face {
    font-family: "Mitr";
    src: url(./font/Mitr-Light.eot);
    src: url(./font/Mitr-Light.eot?#iefix) format("embedded-opentype"),
      url(./font/Mitr-Light.woff2) format("woff2"),
      url(./font/Mitr-Light.woff) format("woff"),
      url(./font/Mitr-Light.ttf) format("truetype"),
      url(./font/Mitr-Light.svg#noto_sans_thairegular) format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }
  @font-face {
    font-family: "Mitr";
    src: url(./font/Mitr-Bold.eot);
    src: url(./font/Mitr-Bold.eot?#iefix) format("embedded-opentype"),
      url(./font/Mitr-Bold.woff2) format("woff2"),
      url(./font/Mitr-Bold.woff) format("woff"),
      url(./font/Mitr-Bold.ttf) format("truetype"),
      url(./font/Mitr-Bold.svg#noto_sans_thairegular) format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }
  @font-face {
    font-family: "Mitr";
    src: url(./font/Mitr-SemiBold.eot);
    src: url(./font/Mitr-SemiBold.eot?#iefix) format("embedded-opentype"),
      url(./font/Mitr-SemiBold.woff2) format("woff2"),
      url(./font/Mitr-SemiBold.woff) format("woff"),
      url(./font/Mitr-SemiBold.ttf) format("truetype"),
      url(./font/Mitr-SemiBold.svg#noto_sans_thairegular) format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }

  /* ----------------------------------------------------------------------------------- */
  @font-face {
    font-family: "Quicksand";
    src: url(./font/Quicksand-Regular.eot);
    src: url(./font/Quicksand-Regular.eot?#iefix) format("embedded-opentype"),
      url(./font/Quicksand-Regular.woff2) format("woff2"),
      url(./font/Quicksand-Regular.woff) format("woff"),
      url(./font/Quicksand-Regular.ttf) format("truetype"),
      url(./font/Quicksand-Regular.svg#noto_sans_thairegular) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }

  @font-face {
    font-family: "Quicksand";
    src: url(./font/Quicksand-Light.eot);
    src: url(./font/Quicksand-Light.eot?#iefix) format("embedded-opentype"),
      url(./font/Quicksand-Light.woff2) format("woff2"),
      url(./font/Quicksand-Light.woff) format("woff"),
      url(./font/Quicksand-Light.ttf) format("truetype"),
      url(./font/Quicksand-Light.svg#noto_sans_thairegular) format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }
  @font-face {
    font-family: "Quicksand";
    src: url(./font/Quicksand-Bold.eot);
    src: url(./font/Quicksand-Bold.eot?#iefix) format("embedded-opentype"),
      url(./font/Quicksand-Bold.woff2) format("woff2"),
      url(./font/Quicksand-Bold.woff) format("woff"),
      url(./font/Quicksand-Bold.ttf) format("truetype"),
      url(./font/Quicksand-Bold.svg#noto_sans_thairegular) format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }
  @font-face {
    font-family: "Quicksand";
    src: url(./font/Quicksand-SemiBold.eot);
    src: url(./font/Quicksand-SemiBold.eot?#iefix) format("embedded-opentype"),
      url(./font/Quicksand-SemiBold.woff2) format("woff2"),
      url(./font/Quicksand-SemiBold.woff) format("woff"),
      url(./font/Quicksand-SemiBold.ttf) format("truetype"),
      url(./font/Quicksand-SemiBold.svg#noto_sans_thairegular) format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0;
  }
  
  body {
    font-family: "Quicksand","Mitr", "sans-serif";
  }
  